import {
  Bars3Icon,
  ClockIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Divider, Drawer, Radio, useMediaQuery } from "@mui/material";
import { useClickAway } from "@uidotdev/usehooks";
import Cookies from "js-cookie";
import { debounce } from "lodash";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Color from "../../../../assets/colors";
import { assetDomain } from "../../../../config/config";
import { formCompanyQueryParam } from "../../../../functions/company";
import { getItem, setItem } from "../../../../helpers/data_management";
import { handleSwitchCountry } from "../../../../helpers/link_management";
import { setEmployerParams } from "../../../../helpers/multicountry_management";
import { sendTrackingEvent } from "../../../../helpers/tracking_management";
import {
  clearCompanyList,
  getTopEightCompanies,
  updateCompanySearchKeyword,
} from "../../../../redux/actions/company_action";
import {
  clearSearchSuggestions,
  fetchSearchSuggestions,
  getTrendingJobKeywords,
  updateJobListFilter,
  updateJobListSearchKeyword,
  updateJobSearchKeyword,
} from "../../../../redux/actions/job_action";
import {
  updateShowMobileFilter,
  updateShowSignInModalStatus,
  updateSignInModalSignUpStatus,
  updateSignUpOrigin,
} from "../../../../redux/actions/navbar_action";
import {
  checkAuthentication,
  checkPDPAStatus,
  hydrateUserObject,
  logout,
  newGetUserInfo,
  updateUserProfileDrawerIndex,
} from "../../../../redux/actions/user_action";
import { store } from "../../../../redux/stores/store";
import * as types from "../../../../redux/types/user_type";
import WithOnboarding from "../../../hoc/WithOnboarding";
import Login from "../../../onboarding/Login/Login";
import ProfilePreviewDialog from "../../../onboarding/ProfilePreviewDialog/ProfilePreviewDialog";
import ResumeConfirmationDialog from "../../../onboarding/ResumeConfirmationDialog/ResumeConfirmationDialog";
import SignUpProfileDialog from "../../../onboarding/SignUpProfileDialog/SignUpProfileDialog";
import SharedJobPreferenceNotification from "../../SharedJobPreferenceNotification/SharedJobPreferenceNotification";
import SharedMobileFilterDialog from "../../SharedMobileFilterDialog/SharedMobileFilterDialog";
import SharedPrivacyDialog from "../../SharedPrivacyDialog/SharedPrivacyDialog";
import SharedSnackbar from "../../SharedSnackbar/SharedSnackbar";
import {
  AppbarStyled,
  AvatarStyled,
  ChevronDownStyled,
  ChevronUpStyled,
  ChipStyled,
  ClickAwayListenerStyled,
  CompanyIcon,
  CountryGrowStyled,
  CountryMenuItem,
  CountryOptionName,
  DrawerCountryToggleGrid,
  DrawerLoginButton,
  DrawerSignUpButton,
  GrowStyled,
  HomepageButton,
  HomepageButtonContainer,
  IconButtonStyled,
  JobsIcon,
  LinkContainer,
  LinkStyled,
  LoginButton,
  LogoStyled,
  MYCountryFlag,
  MagnifyingGlassIconStyled,
  MenuItemStyled,
  MenuListStyled,
  NavbarLinksStyled,
  PaperStyled,
  PopperStyled,
  RecentSearchText,
  SGCountryFlag,
  SearchDropdownContainer,
  SearchInputContainer,
  SearchSuggestionContainer,
  SignUpButton,
  SparkleIconStyled,
  TextButtonStyled,
  ToolbarStyled,
  TypeformButton,
  TypographyStyled,
  VanillaGrid,
} from "./styles";

function SharedNavbar2(props) {
  const { page } = props;
  const isDesktop = useMediaQuery("(min-width:1280px)");

  var typeformId = process.env.NEXT_PUBLIC_WORK_PERSONA_ID;

  const avatarAnchorRef = useRef(null);
  const countryAnchorRef = useRef(null);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openSearchDropdown, setOpenSearchDropdown] = useState(false);
  const [openProfilePopper, setOpenProfilePopper] = useState(false);
  const [openCountryPopper, setOpenCountryPopper] = useState(false);
  const [showSearchbar, setShowSearchbar] = useState(false);
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // job preference popup
  const [openNotification, setOpenNotification] = useState(false);

  // TODO: write function for redux hydration for the user profile
  const reduxUser = useSelector(
    (state) => {
      const { user } = state?.user || {};
      const { name, email, pdpaAccepted, profileImage } = user;

      if (Object.keys(user).length < 1) {
        return {};
      }

      return { name, email, pdpaAccepted, profileImage };
    },
    (prev, next) => {
      return (
        prev.name === next.name &&
        prev.email === next.email &&
        prev.pdpaAccepted === next.pdpaAccepted &&
        prev.profileImage === next.profileImage
      );
    }
  );

  const openLoginBool = useSelector((state) => state?.navbar?.showSignInModal);
  const isSigningUp = useSelector((state) => state?.navbar?.isSignUp);
  const showMobileFilter = useSelector(
    (state) => state?.navbar?.showMobileFilter
  );
  const jobSearchOptionKeyword = useSelector(
    (state) => state?.jobs?.jobSearchKeyword
  );
  const reduxJobListFilter = useSelector((state) => state.jobs?.jobListFilter);

  const reduxUserPersonaId = useSelector(
    (state) => state.user?.workPersona?.attributes?.personaId
  );

  const dispatch = useDispatch();

  // breakpoint
  const desktopSize = useMediaQuery("(min-width: 1280px)");
  const tabletSize = useMediaQuery("(min-width: 768px)");

  // adding this for hiredly logo to align center when screen is too big
  const mobileLSize = useMediaQuery("(max-width: 767px)");
  const mobileSize = useMediaQuery("(max-width: 450px)");

  const router = useRouter();

  const popupCount = parseInt(localStorage.getItem("job_preference_clicked"));

  const urlSubdomain = window?.location?.hostname
    .split(".")
    .at(0)
    .toString()
    .toLowerCase();

  function getReduxStoreFilter() {
    if (page == "company-list-page") {
      return store.getState().companies.companyFilter;
    } else if (
      page == "user-landing-page" ||
      store.getState().jobs.fromLandingPage === true
    ) {
      return store.getState().jobs.jobListFilterLanding;
    } else {
      return reduxJobListFilter;
    }
  }

  function getEnvCountry() {
    return urlSubdomain.includes("my")
      ? "MY"
      : urlSubdomain.includes("sg")
      ? "SG"
      : "TEST";
  }

  function getSearchbarText() {
    const path = router.pathname;

    return path.includes("/companies") ? "Company Name" : "Discover jobs";
  }

  function calcShowSearchbar() {
    const routePath = router.asPath;

    if (
      routePath.includes("/jobs") ||
      routePath.includes("/companies") ||
      page == "job-list-page"
    ) {
      setShowSearchbar(true);
    } else {
      setShowSearchbar(false);
    }
  }

  function calcShowSearchbarDropdown(event) {
    event.stopPropagation();
    event.preventDefault();

    if (tabletSize) {
      setOpenSearchDropdown(true);
    } else {
      dispatch(updateShowMobileFilter(true));

      const searchOnClickListener = (e) => {
        if (event !== e) {
          window.removeEventListener("click", searchOnClickListener);
        }
      };
    }
  }

  function handleCloseModal() {
    dispatch(updateShowSignInModalStatus(false));
    dispatch(updateSignInModalSignUpStatus(false));

    // in case forget password modal is open, reset to false everytime modal closes
    setIsForgetPassword(false);
  }

  // Perform redux hydration before attempt to fetch user info
  async function handleRecallGetUser() {
    const localStorageUser = localStorage.getItem(types.USER_PROFILE);

    if (localStorageUser) {
      return dispatch(hydrateUserObject(JSON.parse(localStorageUser)));
    }

    // I THINK I FOUND OUT WHY. MUST ALSO CHECK IF LOCALSTORAGE IS NOT FALSEY AS WELL, OTHERWISE IT WILL ALWAYS GET NEW USER INFO
    if (Object.keys(reduxUser ?? {}).length <= 0) {
      return dispatch(newGetUserInfo());
    }

    return;
  }

  const debounceFetchSearchSuggestion = debounce(async (keywordProps) => {
    let params = {
      keyword: keywordProps,
    };
    await dispatch(fetchSearchSuggestions(params));
  }, 300);

  const debounceFetchSearchSuggest = useCallback(
    (value) => debounceFetchSearchSuggestion(value),
    []
  );

  const debounceDispatchKeywordUpdate = debounce((value) => {
    if (router.asPath.includes("/companies")) {
      return dispatch(updateCompanySearchKeyword({ keyword: value }));
    }

    dispatch(updateJobListSearchKeyword({ keyword: value }));
  }, 500);

  function handleSearchSuggestion(event, value) {
    const { value: _value } = event?.target || {};

    // prioritise using _value, value as fallback
    const consolidateVal = _value ? _value : value ? value : "";

    dispatch(updateJobSearchKeyword({ keyword: consolidateVal }));
    debounceDispatchKeywordUpdate(consolidateVal);

    if (event?.target?.value?.length < 2) {
      return;
    }

    // only companies list and company profile don't need search suggestion
    if (!router.asPath.includes("/companies")) {
      debounceFetchSearchSuggest(consolidateVal);
    }
  }

  async function handleSearchTermClicked(searchTerm, type) {
    scrollTo(0, 0);
    const pathName = router.asPath;

    if (searchTerm && pathName.includes("/companies")) {
      router.push("/companies", null, { shallow: true });
      store.getState().companies.isLoadingCompanies = true;

      await dispatch(clearCompanyList());

      const companyFilter = {
        ...(store.getState().companies.companyFilter ?? {}),
        keyword: searchTerm,
      };

      const companyQueryParam = formCompanyQueryParam(companyFilter, true);

      await dispatch(getTopEightCompanies(companyQueryParam));

      store.getState().companies.isLoadingCompanies = false;

      // any other page that isn't companies list or company profile page
    } else if (page === "single-job-page") {
      dispatch(updateJobListFilter({ keyword: searchTerm })).then(() => {
        dispatch(updateJobSearchKeyword({ keyword: searchTerm }));

        const keywordSlug =
          searchTerm.toLowerCase()?.replace(/\s+/g, "-") + "-jobs";

        router.push(`/${keywordSlug}`);
      });
    } else if (searchTerm) {
      const keyword = searchTerm ? searchTerm : currentSearch;
      const query = router.query;

      dispatch(updateJobListFilter({ keyword: keyword })).then(() => {
        dispatch(updateJobSearchKeyword({ keyword: keyword }));
        const { filter, ...queryParams } = query;

        const filteredQuery =
          query.filter.includes("jobs") ||
          query.filter.some((item) => item.endsWith("-jobs"))
            ? query.filter.filter(
                (item) => !item.includes("jobs") && !item.endsWith("-jobs")
              )
            : query.filter;

        const cleanedQuery =
          filteredQuery &&
          filteredQuery.map((item) => {
            if (item.startsWith("jobs-in-")) {
              return item.replace("jobs-", "");
            }
            return item;
          });

        const keywordSlug =
          keyword.toLowerCase()?.replace(/\s+/g, "-") + "-jobs/";
        const slug = keywordSlug + cleanedQuery.join("/");

        if (keyword) {
          // trigger custom event
          if (type == "recent-search") {
            sendTrackingEvent({
              event: "CE_search-job-recent-nav-job-list",
              search_term: keyword,
            });
          } else if (type == "trending-search") {
            sendTrackingEvent({
              event: "CE_search-job-trending-nav-job-list",
              search_term: keyword,
            });
          } else if (type == "suggested-search") {
            sendTrackingEvent({
              event: "CE_search-job-suggestion-nav-job-list",
              search_term: keyword,
            });
          } else if (type == "nav-bar") {
            sendTrackingEvent({
              event: "CE_search-job-nav-job-list",
              search_term: keyword,
            });
          }

          store.getState().jobs.fromJobsListNavBar = true;
        } else {
          // GA tracking custom event
          if (type == "recent-search") {
            store.getState().jobs.fromNavbarRecentSearches = true;
            store.getState().jobs.fromNavbarTrendingSearches = false;
            store.getState().jobs.fromNavbarSuggestedSearches = false;
          } else if (type == "trending-search") {
            store.getState().jobs.fromNavbarRecentSearches = false;
            store.getState().jobs.fromNavbarTrendingSearches = true;
            store.getState().jobs.fromNavbarSuggestedSearches = false;
          } else if (type == "suggested-search") {
            store.getState().jobs.fromNavbarRecentSearches = false;
            store.getState().jobs.fromNavbarTrendingSearches = false;
            store.getState().jobs.fromNavbarSuggestedSearches = true;
          }

          store.getState().navbar.fromNavbarSearch = true;
          router.push("/jobs");
        }

        if (page === "job-list-page") {
          router.push(
            {
              pathname: slug ? slug : "/jobs",
              query: queryParams,
            },
            undefined,
            { shallow: true }
          );
        } else {
          router.push({
            pathname: slug ? slug : "/jobs",
            query: queryParams,
          });
        }
      });
    }

    setOpenSearchDropdown(false);
    dispatch(clearSearchSuggestions());
  }

  useEffect(() => {
    calcShowSearchbar();

    // handle for redux joblistfilter keyword more than 1
    // and fetch search suggestion

    const reduxKeyword = getReduxStoreFilter()["keyword"] ?? "";
    if (reduxKeyword && reduxKeyword.length > 2) {
      handleSearchSuggestion(null, reduxKeyword);
    }

    return () => {
      dispatch(updateSignInModalSignUpStatus(false));
      dispatch(updateShowSignInModalStatus(false));
    };
  }, [router.asPath]);

  // for when user exits profile after visiting for you page
  // prevent for you button in navbar from still being highlighted
  useEffect(() => {
    return () => {
      dispatch(updateUserProfileDrawerIndex(-1));
    };
  }, [router.route]);

  // recall get user info api if redux not already populated
  useEffect(() => {
    if (checkAuthentication()) {
      handleRecallGetUser();

      if (!reduxUser.pdpaAccepted && reduxUser.pdpaAccepted !== undefined) {
        dispatch(checkPDPAStatus());
      }
    }
  }, [dispatch]);

  useEffect(() => {
    function updateLocalKeyword() {
      if (searchTerm !== jobSearchOptionKeyword) {
        setSearchTerm(jobSearchOptionKeyword);
      } else {
        return;
      }
    }

    updateLocalKeyword();
  }, [jobSearchOptionKeyword]);

  // controls the visibility of the "Jobs" and "Companies" navigation button in the navbar
  function controlNavigateButtonVisible() {
    // if below tablet - don't show
    if (!tabletSize) {
      return false;
      // if desktop, always show
    } else if (desktopSize) {
      return true;
      // if tablet and above, and searchbar is visible
    } else if (tabletSize && showSearchbar) {
      return false;
    } else if (tabletSize && !showSearchbar) {
      return true;
    }
  }

  function conditionShowCountryToggle() {
    if (tabletSize) {
      return true;
    } else if (showSearchbar) {
      if (Object.keys(reduxUser ?? {}).length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  const searchInputRef = useClickAway(() => {
    tabletSize && setOpenSearchDropdown(false);
  });

  function handleLoginButton() {
    dispatch(updateSignUpOrigin("navbar")).then(() => {
      dispatch(updateShowSignInModalStatus(true));
    });
  }

  function handleSignupButton() {
    dispatch(updateSignUpOrigin("navbar")).then(() => {
      dispatch(updateSignInModalSignUpStatus(true));
      dispatch(updateShowSignInModalStatus(true));
    });
  }

  return (
    <Fragment>
      {/* boolean needs to be string because transient prop cannot have boolean */}
      <AppbarStyled
        $employer={router.pathname === "/employer"}
        $homepage={router.pathname === "/" ? "true" : "false"}
      >
        <ToolbarStyled>
          {/* Logo and navigation buttons */}
          <VanillaGrid
            container
            alignItems={"center"}
            columnGap={"5px"}
            flexWrap={"nowrap"}
            flex={1}
          >
            {/* Hamburger menu */}
            {!desktopSize ? (
              <VanillaGrid>
                <IconButtonStyled
                  id="mobile-open-drawer"
                  edge="start"
                  onClick={() => setOpenDrawer(true)}
                >
                  <Bars3Icon width={24} height={24} color="black" />
                </IconButtonStyled>
              </VanillaGrid>
            ) : null}

            {tabletSize || (!tabletSize && !showSearchbar) ? (
              <VanillaGrid
                container
                alignItems={"end"}
                columnGap={"5px"}
                justifyContent={
                  mobileSize ? "center" : mobileLSize ? "left" : "unset"
                }
                flexWrap={"nowrap"}
                width={mobileSize ? "100%" : "fit-content"}
              >
                <a href={"/"} style={{ display: "flex" }}>
                  <LogoStyled
                    id="hiredly-logo-button"
                    draggable={false}
                    src={assetDomain + "/images/landing/hiredly-logo.png"}
                    alt="hiredly-logo"
                  />
                </a>

                <TypographyStyled
                  variant="body1"
                  color={Color.hiredlyPurple}
                  fontWeight={600}
                  fontSize={desktopSize ? "12px" : "10px"}
                  textTransform={"uppercase"}
                  sx={{
                    textDecoration: "none",
                    margin: "0px",
                  }}
                >
                  {getEnvCountry()}
                </TypographyStyled>
              </VanillaGrid>
            ) : null}
            {controlNavigateButtonVisible() ? (
              <Fragment>
                <NavbarLinksStyled
                  id="jobs-tab"
                  href="/jobs"
                  $active={
                    router.pathname === "/jobs" ||
                    (router.pathname.includes("/job-search") &&
                      router.query.slug != "urgent-hiring-jobs")
                  }
                >
                  jobs
                </NavbarLinksStyled>

                <NavbarLinksStyled
                  id="companies-tab"
                  href="/companies"
                  $active={router.pathname === "/companies"}
                  sx={{
                    width: "65px",
                  }}
                >
                  companies
                </NavbarLinksStyled>
              </Fragment>
            ) : null}
          </VanillaGrid>

          {/* Search bar */}
          {showSearchbar && (!isDesktop || page !== "job-list-page") ? (
            <ClickAwayListenerStyled
              onClickAway={() =>
                tabletSize ? setOpenSearchDropdown(false) : {}
              }
            >
              <VanillaGrid
                ref={searchInputRef}
                container
                justifyContent={"center"}
                position={"relative"}
                flex={2}
                sx={{ marginX: "20px" }}
              >
                <SearchInputContainer
                  value={searchTerm}
                  placeholder={getSearchbarText()}
                  onClick={calcShowSearchbarDropdown}
                  readOnly={mobileSize}
                  endAdornment={
                    <IconButtonStyled
                      sx={{
                        backgroundColor: "#512acc",
                        padding: "6px",
                        ":hover": {
                          backgroundColor: "black",
                        },
                      }}
                      onClick={() =>
                        handleSearchTermClicked(searchTerm, "nav-bar")
                      }
                    >
                      <MagnifyingGlassIconStyled color="white" />
                    </IconButtonStyled>
                  }
                  onChange={(event) => handleSearchSuggestion(event)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleSearchTermClicked(
                        event?.target?.value ?? "",
                        "nav-bar"
                      );
                    }
                  }}
                />

                {/* ONLY SHOWS ONCE TABLET AND ABOVE */}
                {openSearchDropdown &&
                !router.asPath.includes("/companies") &&
                tabletSize ? (
                  <SearchDropdown
                    handleSearchTermClicked={handleSearchTermClicked}
                    // setOpenSearchDropdown={setOpenSearchDropdown} // probs not best practice, open for suggestion.
                  />
                ) : null}
              </VanillaGrid>
            </ClickAwayListenerStyled>
          ) : null}

          <SharedMobileFilterDialog
            openSearchDialog={showMobileFilter}
            page={page}
          />

          {/* Right side - login and sign up */}
          <VanillaGrid
            container
            columnGap={"15px"}
            flexWrap={"nowrap"}
            justifyContent={"end"}
            alignItems={"center"}
            width={"fit-content"}
            flex={router.pathname !== "/employer" && 1} // doing this logic otherwise it will occupy space in employer page
          >
            {router.pathname === "/employer" ? (
              <Fragment>
                <TextButtonStyled
                  href={
                    process?.env?.NEXT_PUBLIC_APP_API_URL &&
                    process.env.NEXT_PUBLIC_APP_API_URL ==
                      "https://staging-my.wobbjobs.com"
                      ? "https://staging-employer.hiredly.com/login"
                      : "https://employer.hiredly.com/login"
                  }
                  variant="text"
                  id="employer-login-button"
                >
                  Employer Login
                </TextButtonStyled>

                <TextButtonStyled
                  href={
                    process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "sg"
                      ? "http://hub.hiredly.com/hiredly-sg-employer-waitlist"
                      : setEmployerParams()
                  }
                  variant="text"
                  id="start-hiring-today-button"
                >
                  Employer Sign-Up
                </TextButtonStyled>
              </Fragment>
            ) : Object.keys(reduxUser ?? {}).length > 0 ? (
              <Fragment>
                {desktopSize && (
                  <Fragment>
                    <TypeformButton
                      id={typeformId}
                      hidden={{
                        fullname: reduxUser?.name,
                        email: reduxUser?.email,
                      }}
                      onSubmit={() => {
                        posthog.capture("persona_quiz_complete", {
                          persona: reduxUserPersonaId ?? null,
                          first_time: reduxUserPersonaId ? false : true,
                        });

                        sendTrackingEvent({
                          event: "CE_persona_quiz_complete",
                          persona: reduxUserPersonaId,
                          first_time: !retake ?? true,
                        });
                      }}
                    >
                      <SparkleIconStyled height={"21px"} width={"21px"} />
                      Take a Personality Quiz!
                    </TypeformButton>
                  </Fragment>
                )}

                <ClickAwayListenerStyled
                  onClickAway={() => setOpenProfilePopper(false)}
                >
                  <div>
                    <AvatarStyled
                      id="user-profile-avatar"
                      alt="user-profile-image"
                      ref={avatarAnchorRef}
                      src={reduxUser?.profileImage ?? ""}
                      onClick={() => setOpenProfilePopper(!openProfilePopper)}
                    />
                    <ProfilePopper
                      profilePopBool={openProfilePopper}
                      profileAnchorRef={avatarAnchorRef}
                    />
                  </div>
                </ClickAwayListenerStyled>
              </Fragment>
            ) : (
              <Fragment>
                {!desktopSize &&
                showSearchbar &&
                Object.keys(reduxUser).length < 1 ? null : (
                  <NavbarLinksStyled
                    id="for-employers-button"
                    href="/employer"
                    $active={router.pathname === "/employer"}
                    sx={{
                      width: "120px",
                    }}
                  >
                    for employer
                  </NavbarLinksStyled>
                )}

                {/* CONDITION 1: IF mobile screen size, and search bar is not showing -> SHOW LOGIN */}
                {/* CONDITION 2: IF tablet and above -> SHOW LOGIN */}
                {((mobileLSize && !showSearchbar) || tabletSize) && (
                  <LoginButton
                    id="login-button"
                    onClick={() => handleLoginButton()}
                  >
                    Login
                  </LoginButton>
                )}
                <SignUpButton
                  id="sign-up-button"
                  onClick={() => handleSignupButton()}
                >
                  Sign Up
                </SignUpButton>
              </Fragment>
            )}

            {conditionShowCountryToggle() && (
              <ClickAwayListenerStyled
                onClickAway={() => !openDrawer && setOpenCountryPopper(false)}
              >
                <VanillaGrid>
                  <VanillaGrid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    ref={countryAnchorRef}
                    onClick={() => setOpenCountryPopper(!openCountryPopper)}
                    columnGap={"8px"}
                    sx={{
                      cursor: "pointer",
                    }}
                    flexWrap={"nowrap"}
                  >
                    {!urlSubdomain.includes("sg") ? (
                      <MYCountryFlag />
                    ) : (
                      <SGCountryFlag />
                    )}
                    {openCountryPopper ? (
                      <ChevronUpStyled />
                    ) : (
                      <ChevronDownStyled />
                    )}
                  </VanillaGrid>
                  <CountryTogglePopper
                    countryPopBool={openCountryPopper}
                    countryAnchorRef={countryAnchorRef}
                    subdomain={urlSubdomain}
                    router={router}
                  />
                </VanillaGrid>
              </ClickAwayListenerStyled>
            )}
          </VanillaGrid>
        </ToolbarStyled>

        {/* popup drawer for tablet and below */}
        <Drawer
          PaperProps={{
            sx: {
              width: "100%",
            },
          }}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <VanillaGrid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            padding={"20px 20px 0 20px"}
          >
            <VanillaGrid
              container
              alignItems={"end"}
              columnGap={"5px"}
              width={"fit-content"}
            >
              <a href={"/"} style={{ display: "flex" }}>
                <LogoStyled
                  id="hiredly-logo-button"
                  draggable={false}
                  src={assetDomain + "/images/landing/hiredly-logo.png"}
                  alt="hiredly-logo"
                />
              </a>

              <TypographyStyled
                variant="body1"
                color={Color.hiredlyPurple}
                fontWeight={600}
                fontSize={desktopSize ? "12px" : "10px"}
                textTransform={"uppercase"}
                sx={{
                  textDecoration: "none",
                }}
              >
                {getEnvCountry()}
              </TypographyStyled>
            </VanillaGrid>
            <IconButtonStyled onClick={() => setOpenDrawer(false)}>
              <XMarkIcon width={35} height={35} color="#512acc" />
            </IconButtonStyled>
          </VanillaGrid>
          <VanillaGrid padding={"20px"}>
            <LinkStyled
              href="/jobs"
              width={"100%"}
              onClick={() =>
                sendTrackingEvent({ event: "CE_click-navigation-jobs-navbar" })
              }
            >
              <LinkContainer container>Jobs</LinkContainer>
            </LinkStyled>
            <LinkStyled
              href="/companies"
              width={"100%"}
              onClick={() =>
                sendTrackingEvent({
                  event: "CE_click-navigation-companies-navbar",
                })
              }
            >
              <LinkContainer container>Companies</LinkContainer>
            </LinkStyled>
          </VanillaGrid>
          <Divider style={{ margin: "0 20px" }} />
          <VanillaGrid padding={"20px"}>
            {/* Check if redux user is empty */}
            {router.pathname === "/employer" ? (
              <VanillaGrid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ gap: "24px", marginTop: "20px" }}
              >
                <LinkStyled
                  href={
                    process?.env?.NEXT_PUBLIC_APP_API_URL &&
                    process.env.NEXT_PUBLIC_APP_API_URL ==
                      "https://staging-my.wobbjobs.com"
                      ? "https://staging-employer.hiredly.com/login"
                      : "https://employer.hiredly.com/login"
                  }
                  sx={{ color: "#512acc", width: "100%" }}
                >
                  Employer login
                </LinkStyled>

                <LinkStyled
                  href={
                    process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "sg"
                      ? "http://hub.hiredly.com/hiredly-sg-employer-waitlist"
                      : setEmployerParams()
                  }
                  sx={{ color: "#512acc", width: "100%", marginBottom: "24px" }}
                >
                  Employer Sign-Up
                </LinkStyled>
              </VanillaGrid>
            ) : Object.keys(reduxUser ?? {}).length > 0 ? (
              <Fragment>
                <TypeformButton
                  id={typeformId}
                  hidden={{
                    fullname: reduxUser?.name,
                    email: reduxUser?.email,
                  }}
                  onSubmit={() => {
                    posthog.capture("persona_quiz_complete", {
                      persona: reduxUserPersonaId ?? null,
                      first_time: reduxUserPersonaId ? false : true,
                    });

                    sendTrackingEvent({
                      event: "CE_persona_quiz_complete",
                      persona: reduxUserPersonaId,
                      first_time: !retake ?? true,
                    });
                  }}
                >
                  <SparkleIconStyled height={"21px"} width={"21px"} />
                  Take a Personality Quiz!
                </TypeformButton>
                <LinkStyled href="/employer" width={"100%"}>
                  <LinkContainer container>For Employer</LinkContainer>
                </LinkStyled>
              </Fragment>
            ) : (
              <VanillaGrid container marginBottom={"37px"}>
                <LinkStyled href="/employer" width={"100%"}>
                  <LinkContainer container>For Employer</LinkContainer>
                </LinkStyled>
                <VanillaGrid
                  container
                  justifyContent={tabletSize ? "flex-start" : "center"}
                  alignItems={"center"}
                  sx={{ gap: "24px", marginTop: "20px" }}
                >
                  <DrawerLoginButton
                    id="log-in-button"
                    onClick={() => dispatch(updateShowSignInModalStatus(true))}
                  >
                    Login
                  </DrawerLoginButton>
                  <DrawerSignUpButton
                    id="sign-up-button"
                    onClick={() => {
                      handleSignupButton();
                    }}
                  >
                    Sign Up
                  </DrawerSignUpButton>
                </VanillaGrid>
              </VanillaGrid>
            )}

            {/* COUNTRY TOGGLE STARTS HERE */}
            <VanillaGrid
              container
              justifyContent={tabletSize ? "flex-start" : "center"}
            >
              <DrawerCountryOption
                openCountryPopper={openCountryPopper}
                setOpenCountryPopper={setOpenCountryPopper}
                subdomain={urlSubdomain}
                router={router}
              />
              {/* COUNTRY TOGGLE ENDS HERE */}
            </VanillaGrid>
          </VanillaGrid>
        </Drawer>

        {/* Login / Sign up dialog */}
        <Login
          open={openLoginBool}
          handleClose={handleCloseModal}
          signUp={isSigningUp}
          handleClickSignUp={() =>
            dispatch(updateSignInModalSignUpStatus(true))
          }
          handleClickSignIn={() =>
            dispatch(updateSignInModalSignUpStatus(false))
          }
          forgotPassword={isForgetPassword}
          handleOpenForgotPassword={() => setIsForgetPassword(true)}
        />
        {/* Putting this here as Login will unmount everytime the Login dialog closes. */}
        {/* Cannot declare the below dialog in Login */}
        {/* for choosing if user has existing resume or not */}
        <ResumeConfirmationDialog />
        {/* for filling basic info and attaching resume to build profile */}
        <SignUpProfileDialog />
        {/* for preview profile after reading basic info and resume from above */}
        <ProfilePreviewDialog />

        <SharedPrivacyDialog />

        {/* Job Preference popup */}
        <SharedJobPreferenceNotification
          popupCount={popupCount}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
        />

        {router.pathname === "/" && !desktopSize && (
          <HomepageButtonContainer
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <HomepageButton
              id="mobile-jobs-button"
              jobs_button={true}
              onClick={() => {
                sendTrackingEvent({ event: "CE_click-jobs-homepage" });
                router.push("/jobs");
              }}
            >
              <JobsIcon />
              Jobs
            </HomepageButton>
            <HomepageButton
              id="mobile-companies-button"
              onClick={() => {
                sendTrackingEvent({ event: "CE_click-companies-homepage" });
                router.push("/companies");
              }}
            >
              <CompanyIcon />
              Companies
            </HomepageButton>
          </HomepageButtonContainer>
        )}
      </AppbarStyled>

      {/* Snackbar declaration */}
      <SharedSnackbar />
    </Fragment>
  );
}

function ProfilePopper({ profilePopBool, profileAnchorRef }) {
  const reduxUserName = useSelector((state) => state?.user?.user?.name);

  const router = useRouter();

  const dispatch = useDispatch();

  async function onSignoutClicked() {
    const logoutRes = await dispatch(logout());

    if (logoutRes.type === types.LOGOUT) {
      Cookies.remove("userId"); // Delete cookie for user ID sent to GA when user logs out
      Cookies.set("isRegistered", false, { expires: 365 }); // Check if user is logged out and change cookie to false for CleverTap
      Cookies.remove("token");

      sendTrackingEvent({ event: "CE_logout" });

      localStorage.removeItem("shown_resume_banner_highlight"); // Remove the key that determines whether show resume banner highlight upon log out
      localStorage.removeItem("show-tacf-/profile");
      localStorage.removeItem("show-tacf-/companies");
      localStorage.removeItem("show-tacf-/jobs");

      if (router.pathname === "/") {
        router.reload();
      } else {
        router.push("/");
      }
    }
  }

  return (
    <PopperStyled
      open={profilePopBool}
      anchorEl={profileAnchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps }) => (
        <GrowStyled {...TransitionProps}>
          <PaperStyled>
            <MenuListStyled width={"100%"}>
              <MenuItemStyled
                sx={{
                  fontWeight: "700",
                }}
              >
                {reduxUserName}
              </MenuItemStyled>
              <MenuItemStyled
                id={"user-view-profile-button"}
                onClick={() => {
                  dispatch(updateUserProfileDrawerIndex(0));
                  router.push("/profile#overview");
                }}
                selected={
                  router.pathname === "/profile" &&
                  router.asPath.includes("overview")
                }
              >
                Profile
              </MenuItemStyled>
              <MenuItemStyled
                id={"user-manage-job-alerts-button"}
                onClick={() => {
                  dispatch(updateUserProfileDrawerIndex(3));
                  router.push("/profile#manage-job-alerts");
                }}
                selected={
                  router.pathname === "/profile" &&
                  router.asPath.includes("manage-job-alerts")
                }
              >
                Job Alerts
              </MenuItemStyled>
              <MenuItemStyled
                id={"user-job-application-history-button"}
                onClick={() => {
                  router.push("/profile#job-application-history");
                  dispatch(updateUserProfileDrawerIndex(5));
                }}
                selected={
                  router.pathname === "/profile" &&
                  router.asPath.includes("job-application-history")
                }
              >
                Job Application Status
              </MenuItemStyled>
              <MenuItemStyled id="log-out-button" onClick={onSignoutClicked}>
                Logout
              </MenuItemStyled>
            </MenuListStyled>
          </PaperStyled>
        </GrowStyled>
      )}
    </PopperStyled>
  );
}

function CountryTogglePopper({ countryPopBool, countryAnchorRef, router }) {
  return (
    <PopperStyled
      open={countryPopBool}
      anchorEl={countryAnchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps }) => (
        <CountryGrowStyled {...TransitionProps}>
          <PaperStyled>
            <MenuListStyled width={"100%"}>
              <CountryMenuItem
                onClick={() =>
                  handleSwitchCountry({ country: "my", router: router })
                }
              >
                <MYCountryFlag isPopper />
                <CountryOptionName variant="body1">MY</CountryOptionName>
              </CountryMenuItem>
              <CountryMenuItem
                onClick={() =>
                  handleSwitchCountry({ country: "sg", router: router })
                }
              >
                <SGCountryFlag isPopper />
                <CountryOptionName variant="body1">SG</CountryOptionName>
              </CountryMenuItem>
            </MenuListStyled>
          </PaperStyled>
        </CountryGrowStyled>
      )}
    </PopperStyled>
  );
}

function SearchDropdown({ handleSearchTermClicked }) {
  const [recentSearchState, setRecentSearchState] = useState([]);

  // redux related
  const reduxTrendingKeywords = useSelector(
    (state) => state?.jobs?.trendingJobKeywords
  );
  const jobSearchKeyword = useSelector(
    (state) => state.jobs?.jobListFilter?.keyword
  );
  const currentSearch = useSelector((state) => state?.jobs?.jobSearchKeyword);
  const jobSearchData = useSelector((state) => state?.jobs?.searchData);

  const dispatch = useDispatch();

  function boldMatchingText(suggestion) {
    const keyword = currentSearch.toLowerCase();

    const lowerCaseSuggestion = suggestion.toLowerCase();
    const index = lowerCaseSuggestion.indexOf(keyword);

    if (!keyword || index === -1) {
      return (
        <SearchSuggestionContainer
          onClick={() => handleSearchTermClicked(suggestion, "nav-bar")}
        >
          {suggestion}
        </SearchSuggestionContainer>
      );
    }

    const beforeMatch = suggestion.substring(0, index);
    const match = suggestion.substring(index, index + keyword.length);
    const afterMatch = suggestion.substring(index + keyword.length);

    return (
      <SearchSuggestionContainer
        onClick={() => handleSearchTermClicked(suggestion, "nav-bar")}
      >
        <span>
          {beforeMatch}
          <b>{match}</b>
          {afterMatch}
        </span>
      </SearchSuggestionContainer>
    );
  }

  function deleteRecentSearch(value) {
    let updatedSearches = recentSearchState.filter(
      (recentSearch) => recentSearch.query !== value
    );
    setRecentSearchState(updatedSearches);
    setItem("recent-searches", JSON.stringify(updatedSearches));
  }

  useEffect(() => {
    // for recent search keywords
    setRecentSearchState(JSON.parse(getItem("recent-searches")));

    // handle for re-fetch trending keyword at least once
    if (
      Array.isArray(reduxTrendingKeywords) &&
      reduxTrendingKeywords.length < 1
    ) {
      dispatch(getTrendingJobKeywords({ limit: 10 }));
    }
  }, []);

  useEffect(() => {
    const debounceFetchRecentSearch = debounce(() => {
      setRecentSearchState(JSON.parse(getItem("recent-searches")));
    }, 400);

    debounceFetchRecentSearch(jobSearchKeyword);

    return () => {
      setRecentSearchState([]);
    };
  }, [jobSearchKeyword]);

  // FULFILLMENT CONDITION:
  // state.job.jobListFilter.keyword needs to be prepopulated before landing on job list page
  // handleSearchSuggestion() will trigger automatically in useEffect
  // causing the initial render to go into the first condition, instead of displaying recent and trending keyword.
  return jobSearchKeyword?.length > 2 && jobSearchData?.length > 0 ? (
    <SearchDropdownContainer>
      {jobSearchData.map((data, index) => (
        <div key={index}>{boldMatchingText(data.name)}</div>
      ))}
    </SearchDropdownContainer>
  ) : (
    <SearchDropdownContainer>
      {/* recent searches */}
      {recentSearchState.length > 0 ? (
        <Fragment>
          <RecentSearchText>Recent Searches</RecentSearchText>
          <VanillaGrid container flexDirection={"column"} margin={"10px 0"}>
            {recentSearchState.map((recentSearch) => {
              return (
                <VanillaGrid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{
                    ":hover": {
                      backgroundColor: "#efefef",
                    },
                    cursor: "pointer",
                  }}
                  borderRadius={"15px"}
                  onClick={() =>
                    handleSearchTermClicked(recentSearch.query, "recent-search")
                  }
                >
                  <VanillaGrid
                    container
                    columnGap={"12px"}
                    alignItems={"center"}
                    maxWidth={"80%"}
                  >
                    <ClockIcon
                      width={24}
                      height={24}
                      color="rgba(132, 132, 132, 0.6)"
                    />
                    <RecentSearchText sx={{ fontWeight: "400" }}>
                      {recentSearch.query}
                    </RecentSearchText>
                  </VanillaGrid>
                  <VanillaGrid>
                    <IconButtonStyled
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        deleteRecentSearch(recentSearch.query);
                      }}
                    >
                      <TrashIcon
                        width={24}
                        height={24}
                        color="rgba(132, 132, 132, 0.6)"
                      />
                    </IconButtonStyled>
                  </VanillaGrid>
                </VanillaGrid>
              );
            })}
          </VanillaGrid>
        </Fragment>
      ) : null}

      {/* trending keyword */}
      {reduxTrendingKeywords?.length > 0 ? (
        <Fragment>
          <RecentSearchText>Trending Searches</RecentSearchText>
          <VanillaGrid container flexWrap={"wrap"} flexDirection={"row"}>
            {Array.isArray(reduxTrendingKeywords) &&
              reduxTrendingKeywords.slice(0, 4).map((keyword, index) => {
                return (
                  <ChipStyled
                    icon={<MagnifyingGlassIconStyled color="black" />}
                    label={keyword}
                    key={index}
                    onClick={() =>
                      handleSearchTermClicked(keyword, "trending-search")
                    }
                  />
                );
              })}
          </VanillaGrid>
        </Fragment>
      ) : null}
    </SearchDropdownContainer>
  );
}

function DrawerCountryOption({
  openCountryPopper,
  setOpenCountryPopper,
  subdomain,
  router,
}) {
  return (
    <DrawerCountryToggleGrid
      sx={{
        height: openCountryPopper ? "180px" : "60px",
      }}
      container
      alignItems={"end"}
    >
      {/* Malaysia option */}
      <VanillaGrid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        height={"100%"}
        maxHeight={"60px"}
        padding={"16px"}
        display={openCountryPopper ? "flex" : "none"}
        sx={{
          cursor: "pointer",
          ":hover": {
            backgroundColor: Color.lightGrey,
          },
        }}
        onClick={() => handleSwitchCountry({ country: "my", router: router })}
        flexWrap={"nowrap"}
      >
        <VanillaGrid
          container
          justifyContent={"start"}
          alignItems={"center"}
          columnGap={"24px"}
        >
          <MYCountryFlag isPopper />
          Malaysia
        </VanillaGrid>
        <Radio checked={!subdomain.includes("sg")} />
      </VanillaGrid>

      {/* Singapore option */}
      <VanillaGrid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        height={"100%"}
        maxHeight={"60px"}
        padding={"16px"}
        display={openCountryPopper ? "flex" : "none"}
        sx={{
          cursor: "pointer",
          ":hover": {
            backgroundColor: Color.lightGrey,
          },
        }}
        onClick={() => handleSwitchCountry({ country: "sg", router: router })}
        flexWrap={"nowrap"}
      >
        <VanillaGrid
          container
          justifyContent={"start"}
          alignItems={"center"}
          columnGap={"24px"}
        >
          <SGCountryFlag isPopper />
          Singapore
        </VanillaGrid>
        <Radio checked={subdomain.includes("sg")} />
      </VanillaGrid>

      {/* dropdown current option */}
      <VanillaGrid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        height={"100%"}
        maxHeight={"60px"}
        padding={"16px"}
        flexWrap={"nowrap"}
        sx={{
          cursor: "pointer",
          backgroundColor: Color.lightHiredlyPurple,
        }}
        onClick={() => {
          setOpenCountryPopper(!openCountryPopper);
        }}
        zIndex={100}
      >
        <VanillaGrid
          container
          justifyContent={"start"}
          alignItems={"center"}
          columnGap={"24px"}
        >
          {!subdomain.includes("sg") ? (
            <MYCountryFlag isPopper />
          ) : (
            <SGCountryFlag isPopper />
          )}

          {!subdomain.includes("sg") ? "Malaysia" : "Singapore"}
        </VanillaGrid>
        {openCountryPopper ? <ChevronUpStyled /> : <ChevronDownStyled />}
      </VanillaGrid>
    </DrawerCountryToggleGrid>
  );
}

export default WithOnboarding(SharedNavbar2);
